import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileServiceService {
  api_base_url = environment.apiUrl

  constructor( private router : Router,
    private http: HttpClient
  ) {
    
  }
  private profileUpdateSource = new Subject<void>();

  profileUpdate$ = this.profileUpdateSource.asObservable();

  triggerProfileUpdate() {
    this.profileUpdateSource.next();
  }

  getProfileCreate(token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(`${this.api_base_url}/user/userProfileView`, { headers });
  }
  getProfile() {
    return this.http.get(`${this.api_base_url}/user/userProfileView`);
  }
  createProfile(user: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put(`${this.api_base_url}/user/editProfile`, user, { headers });
  }
  editProfile(user: any) {
    return this.http.put(`${this.api_base_url}/user/editProfile`, user);
  }
  checkUsernameAvailabilityCreate(data: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(`${this.api_base_url}/user/userNameAvailable?user_name=${data.user_name}`, { headers });
  }
  checkUsernameAvailability(data: any) {
    return this.http.get(`${this.api_base_url}/user/userNameAvailable?user_name=${data.user_name}`);
  }
  checkResourceAvailability(data: any) {
    return this.http.get(`${this.api_base_url}/user/resourceAvailable?type=${data.type}&email=${data.email}`);
  }
  checkResourceAvailabilitynumber(data: any) {
    return this.http.get(`${this.api_base_url}/user/resourceAvailable?type=${data.type}&country_code=${data.country_code}&phone=${data.phone}`);
  }
  
  checkResourceAvailabilityCreate(data: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(`${this.api_base_url}/user/resourceAvailable?type=${data.type}&email=${data.email}`, { headers });
  }
  checkResourceAvailabilityCreatenumber(data: any,token:any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(`${this.api_base_url}/user/resourceAvailable?type=${data.type}&country_code=${data.country_code}&phone=${data.phone}`, { headers });
  }
  checkEmailAvailability(data: any) {
    return this.http.post(`${this.api_base_url}/user/emailAvailable`, data);
  }
  checkPhoneNumberAvailability(data: any) {
    return this.http.post(`${this.api_base_url}/user/phoneAvailable`, data);
  }
  globalSearch(searchQuery: any) {
    return this.http.get(`${this.api_base_url}/user/globalSearch?searchQuery=${searchQuery}`);
  }

  userProfileDetail() {
    return this.http.get(`${this.api_base_url}/user/userDetailProfile`);
  }
  getGameListForUserSkill() {
   
    return this.http.get(`${this.api_base_url}/user/getGameListForUserSkill`);
  }
  getUserSkillByUser() {
   
    return this.http.get(`${this.api_base_url}/user/getUserSkillByUser`);
  }
  addUserSkill(data:any) {
   
    return this.http.post(`${this.api_base_url}/user/addUserSkill`,data);
  }
  deleteUserSkill(data:any) {
    
    return this.http.put(`${this.api_base_url}/user/deleteUserSkill`,data);
  }
  updateUserSkill(data:any) {
    
    return this.http.put(`${this.api_base_url}/user/updateUserSkill`,data);
  }
  userProfileSchedule(searchQuery:any,category_id:any) {
    
    let url = `${this.api_base_url}/user/userProfileSchedule?searchQuery=${searchQuery}`;
    let parseCategory = JSON.parse(category_id)
    if (parseCategory && parseCategory.length > 0) {
        url += `&category_id=${category_id}`;
    }
    return this.http.get(url);
  }
  profileRecentMatchesList(searchQuery:any,category_id:any) {
   
    let url = `${this.api_base_url}/user/profileRecentMatchesList?searchQuery=${searchQuery}`;
    let parseCategory = JSON.parse(category_id)
    if (parseCategory && parseCategory.length > 0) {
        url += `&categoryId=${category_id}`;
    }
    return this.http.get(url);
   
  }
  getUserAchievementDetail() {
   
    return this.http.get(`${this.api_base_url}/user/getUserAchievementDetail`);
   
  }
  updateUserLanguage(data:any) {
   
    return this.http.post(`${this.api_base_url}/user/updateUserLanguage`,data);
   
  }
  
  // team
  
  getProfileTeamList() {
    return this.http.get(`${this.api_base_url}/user/getProfileTeamList`);
  }
  getProfileTeamDetailList() {
    return this.http.get(`${this.api_base_url}/user/getProfileTeamDetailList`);
  }
  checkProfileTeamForTournament(data:any) {
    return this.http.get(`${this.api_base_url}/user/checkProfileTeamForTournament?team_id=${data.team_id}&tournament_id=${data.tournament_id}`);
  }
  sendProfileParticipentInvite(data:any) {
    return this.http.post(`${this.api_base_url}/user/sendProfileParticipentInvite`,data);
  }
  getProfileTeamParticipentDetail(team_id:any,tournament_id:any) {
    return this.http.get(`${this.api_base_url}/user/getProfileTeamParticipentDetail?team_id=${team_id}&tournament_id=${tournament_id}`);
  }
  getProfileTeamParticipentDetailteam(team_id:any) {
    return this.http.get(`${this.api_base_url}/user/getProfileTeamParticipentDetail?team_id=${team_id}`);
  }
  acceptProfileParticipentInvite(data:any) {
    return this.http.put(`${this.api_base_url}/user/acceptProfileParticipentInvite`,data);
  }
  deleteProfileParticipant(data:any) {
    return this.http.put(`${this.api_base_url}/user/deleteProfileParticipant`,data);
  }
  getTeamList() {
    return this.http.get(`${this.api_base_url}/user/getTeamList`);
  }
  deleteProfileTeam(data:any) {
    return this.http.put(`${this.api_base_url}/user/deleteProfileTeam`,data);
  }
  editProfileTeam(data:any) {
    return this.http.put(`${this.api_base_url}/user/editProfileTeam`,data);
  }
  editProfileParticipant(data:any) {
    return this.http.put(`${this.api_base_url}/user/editProfileParticipant`,data);
  }
  // other user

  otheruserProfileDetail(userId:any) {
    return this.http.get(`${this.api_base_url}/user/userDetailProfile?userId=${userId}`);
  }
  followProfile(data:any) {
    return this.http.post(`${this.api_base_url}/user/followProfile`,data);
  }
  otherProfileSchedule(userId:any,searchQuery:any,category_id:any) {
    
    let url = `${this.api_base_url}/user/userProfileSchedule?userId=${userId}&searchQuery=${searchQuery}`;
    let parseCategory = JSON.parse(category_id)
    if (parseCategory && parseCategory.length > 0) {
        url += `&category_id=${category_id}`;
    }
    return this.http.get(url);
  }
  othergetUserSkillByUser(userId:any) {
   
    return this.http.get(`${this.api_base_url}/user/getUserSkillByUser?userId=${userId}`);
  }
  othergetProfileTeamDetailList(userId:any) {
   
    return this.http.get(`${this.api_base_url}/user/getProfileTeamDetailList?userId=${userId}`);
  }
  otheruserProfileSchedule(userId:any,searchQuery:any,category_id:any) {
    
    let url = `${this.api_base_url}/user/userProfileSchedule?userId=${userId}&searchQuery=${searchQuery}`;
    let parseCategory = JSON.parse(category_id)
    if (parseCategory && parseCategory.length > 0) {
        url += `&category_id=${category_id}`;
    }
    return this.http.get(url);
  }
  otherprofileRecentMatchesList(userId:any,searchQuery:any,category_id:any) {
   
    let url = `${this.api_base_url}/user/profileRecentMatchesList?userId=${userId}&searchQuery=${searchQuery}`;
    let parseCategory = JSON.parse(category_id)
    if (parseCategory && parseCategory.length > 0) {
        url += `&categoryId=${category_id}`;
    }
    return this.http.get(url);
  }
  othergetUserAchievementDetail(userId:any) {
   
    return this.http.get(`${this.api_base_url}/user/getUserAchievementDetail?userId=${userId}`);
   
  }
  getUserDetails(userId:any) {
    return this.http.get(`${this.api_base_url}/user/getUserDetails?user_id=${userId}`);
  }
}
